import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useAsyncList } from '@react-stately/data';
import SortTable from '../../components/SortTable/SortTable.js';
import RsnField from '../../components/leaderboard/RsnField.js';

const RECORDS_PER_PAGE = 10;

const COLUMNS = [
  {
    key: 'index',
    props: {},
    label: '#'
  },
  {
    key: 'rsn',
    props: {
      allowsSorting: true
    },
    label: 'RSN',
    render: (value) => <RsnField account={value} />
  },
  {
    key: 'rankName',
    props: {},
    label: 'Rank'
  },
  {
    key: 'joinDate',
    props: {},
    label: 'Join Date'
  },
  {
    key: 'embargoScore',
    props: {
      allowsSorting: true
    },
    label: 'Embargo Score',
    render: (value) => <div>{value?.embargoScore?.toLocaleString()}</div>
  },
  {
    key: 'accountPoints',
    props: {
      allowsSorting: true
    },
    label: 'Account Points',
    render: (value) => <div>{value?.accountPoints?.toLocaleString()}</div>
  },
  {
    key: 'communityPoints',
    props: {
      allowsSorting: true
    },
    label: 'Community Points',
    render: (value) => <div>{value?.communityPoints?.toLocaleString()}</div>
  }
];

export const Leaderboard = () => {
  const [isLoading, setIsLoading] = React.useState(true);

  const navigate = useNavigate();

  const list = useAsyncList({
    async load({ signal }) {
      const res = await fetch('/api/leaderboard', {
        signal
      });
      const json = await res.json();
      setIsLoading(false);
      const items = json.map((account, i) => {
        console.log('MAPPING ACCOUNT:', account);
        const { member, rank } = account;
        const { rsn, createdAt: joinDate } = account;
        const { name: rankName } = rank || {};
        const embargoScore = account.accountPoints + member.communityPoints;
        return {
          id: rsn,
          rsn,
          rankName,
          joinDate: moment(joinDate).format('MMM Do YYYY'),
          embargoScore,
          accountPoints: account.accountPoints,
          communityPoints: member.communityPoints,
          avatarUrl: account.avatarUrl,
          rank: rank
        };
      }, []);
      console.log('ITEMS:', items);
      return {
        items
      };
    }
  });

  const onSelectionChange = ({ currentKey }) => {
    navigate(`/profile/${currentKey}`);
  };

  return (
    <div className="flex justify-center m-4">
      <div>
        <SortTable
          columns={COLUMNS}
          records={list.items}
          recordsPerPage={RECORDS_PER_PAGE}
          isLoading={isLoading}
          isSearchable={true}
          searchableField="rsn"
          className="m-2 min-h-96"
          onSelectionChange={onSelectionChange}
          selectionMode="single"
        />
      </div>
    </div>
  );
};

export default Leaderboard;
