import LandingPage from './LandingPage/LandingPage.js';
import Leaderboard from './Leaderboard/Leaderboard.js';
import DiscordRedirect from './DiscordRedirect/DiscordRedirect.js';
import Profile from './Profile/Profile.js';
import { RouterProvider } from 'react-router-dom';
import { NextUIProvider } from '@nextui-org/react';
import Nav from '../components/Nav/Nav.js';
import Points from './Points/Points.js';

import { createBrowserRouter } from 'react-router-dom';
import { UserProvider } from '../hooks/use-user.js';
import Ranks from './Ranks/Ranks.js';
import { TestCookiePage } from './Test/TestCookiePage.js';

const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPage />
  },
  {
    path: '/discord',
    element: <DiscordRedirect />
  },
  {
    path: '/leaderboard',
    element: <Leaderboard />
  },
  {
    path: '/profile/:rsn',
    element: <Profile />
  },
  {
    path: '/points',
    element: <Points />
  },
  {
    path: '/ranks',
    element: <Ranks />
  },
  {
    path: '/test-cookie-iframe-setting',
    element: <TestCookiePage />
  }
]);

export const App = () => {
  return (
    <>
      <Nav />
      <UserProvider>
        <RouterProvider router={router}>
          <NextUIProvider navigate={router} />
        </RouterProvider>
      </UserProvider>
    </>
  );
};

export default App;
