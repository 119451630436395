import React from 'react';

export const TestCookiePage = () => {
  React.useEffect(() => {
    fetch('/api/test-cookie-setting').then((res) => {
      console.log('whole response:', res);
      res.json().then((data) => {
        console.log('json:', data);
      });
    });
  });

  return (
    <div>
      <h1>Test Cookie Page</h1>
    </div>
  );
};
