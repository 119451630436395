import React from 'react';
import { useAsyncList } from '@react-stately/data';
import Accolade from '../../components/profile/accolades/Accolade.js';
import { Card, Textarea } from '@nextui-org/react';

export const Ranks = () => {
  const ranks = useAsyncList({
    async load({ signal }) {
      const response = await fetch('/api/ranks', { signal });
      const data = await response.json();
      return {
        items: data.sort((a, b) => a.ordinance - b.ordinance)
      };
    }
  });
  const generateGearRequirements = React.useCallback((rank) => {
    if (rank.trackableGear.length === 0 && rank.untrackableGear.length === 0) {
      return 'None';
    }
    return rank.trackableGear.concat(rank.untrackableGear).join('\n');
  }, []);
  console.log('RANKS:', ranks);
  return (
    <div>
      <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 p-4 md:p-6">
        {ranks?.items?.map((rank, i) => (
          <Card key={i} className="flex flex-col gap-4 p-6">
            <div className="flex items-center gap-4">
              <div className="bg-muted rounded-full p-3 flex items-center justify-center">
                <Accolade accoladeKey={rank.name} />
              </div>
              <div className="text-xl font-semibold">{rank.name}</div>
            </div>
            <div className="grid gap-2 text-sm">
              <div className="flex items-center justify-between">
                <div className="text-muted-foreground">Total level</div>
                <div>{rank.totalLevel}</div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-muted-foreground">EHB</div>
                <div>{rank.ehb}</div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-muted-foreground">Community points</div>
                <div>{rank.requiredCommunityPoints}</div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-muted-foreground">Account points</div>
                <div>{rank.requiredAccountPoints}</div>
              </div>
              <div>
                <div className="text-muted-foreground pb-2">
                  Gear/Account Requirements
                </div>
                <Textarea
                  isReadOnly
                  className="max-w-xs"
                  defaultValue={generateGearRequirements(rank)}
                  labelPlacement="outside"
                  variant="bordered"
                />
              </div>
            </div>
          </Card>
        ))}
      </section>
    </div>
  );
};

function PowerIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 2v10" />
      <path d="M18.4 6.6a9 9 0 1 1-12.77.04" />
    </svg>
  );
}

export default Ranks;
